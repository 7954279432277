import './App.css';
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom';
import Gpt from './Components/Gpt';
import './Components/Gpt.css';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Gpt/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
